import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { Alert, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import { Input, Button, Select } from "design-react-kit";
import { GenericAPI } from "../../Apis/GenericAPI";
import listAPI from "../../Apis/EnumAPI";

export default function EditMunicipality(props) {
  const navigate = useNavigate();
  let params = useParams();
  const [, setValue] = useState([]);

  const defaultOptions = [
    { value: 1, label: "Rosso" },
    { value: 2, label: "Arancio" },
    { value: 3, label: "Verde" },
    { value: 4, label: "Viola" },
    { value: 5, label: "Azzurro" },
    { value: 6, label: "Blu" },
    { value : 7, label : "Azzurro Halley" }
  ];


  const defaultOptionsApi = [
    { value: true, label: "Si" },
    { value: false, label: "No" },
  ];

  function getSelectedOption(val) {
    const res = defaultOptions.find((obj) => {
      if (obj.value == val) return obj;
    });
    let result = [];
    result.push(res);
    return result;
  }

  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [domain, setDomain] = useState(null);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");
  const createFormRef = useRef(null);

  function back() {
    navigate(-1);
  }

  function getAlert() {
    if (show) {
      return (
        <Alert variant={variant} onClose={() => setShow(false)} dismissible>
          <p>{message}</p>
        </Alert>
      );
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData(createFormRef.current);
      const formDataJSON = Object.fromEntries(formData);

      await GenericAPI.update(listAPI.Tenants, formDataJSON, false).then(
        (response) => {
          if (response.success) {
            setVariant("success");
            setMessage("Operazione eseguita correttamente!!");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setShow(true);

            setTimeout(() => {
              setShow(false);
            }, 5000);
          } else {
            setVariant("danger");
            setMessage("Operazione non riuscita!! " + response.message);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setShow(true);

            setTimeout(() => {
              setShow(false);
            }, 5000);
          }
        }
      );
      // back();
    } catch (e) {
      setVariant("danger");
      setMessage("Operazione non riuscita!! " + e);
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const onChangeEdit = (e) => {
    switch (e.target.name) {
      case "saveAll":
        setTenant((prev) => {
          return { ...prev, saveAll: e.target.value };
        });
        break;
      case "id":
        setTenant((prev) => {
          return { ...prev, id: e.target.value };
        });
        break;
      case "description":
        setTenant((prev) => {
          return { ...prev, description: e.target.value };
        });
        break;
      case "country":
        setTenant((prev) => {
          return { ...prev, country: e.target.value };
        });
        break;
      case "country_url":
        setTenant((prev) => {
          return { ...prev, country_url: e.target.value };
        });
        break;  
      case "domain":
        setDomain((prev) => {
          return { ...prev, domain: e.target.value };
        });
        break;
      case "domain_tenant":
        setTenant((prev) => {
          return { ...prev, domain: e.target.value };
        });
        break;
      case "themeSelected":
        setTenant((prev) => {
          return { ...prev, themeSelected: e.target.value };
        });
        break;
      case "siteUrl":
        setTenant((prev) => {
          return { ...prev, siteUrl: e.target.value };
        });
        break;
      case "serviceSiteUrl":
        setTenant((prev) => {
          return { ...prev, serviceSiteUrl: e.target.value };
        });
        break;
      case "codiceEnte":
        setTenant((prev) => {
          return { ...prev, codiceEnte: e.target.value };
        });
        break;
      case "uav":
        setTenant((prev) => {
          console.log(prev);
          return { ...prev, uav: e.target.value };
        });
        break;
      case "partitaIva":
        setTenant((prev) => {
          return { ...prev, partitaIva: e.target.value };
        });
        break;
      case "linkPrivacy":
        setTenant((prev) => {
          return { ...prev, linkPrivacy: e.target.value };
        });
        break;
      case "linkContactUs":
        setTenant((prev) => {
          return { ...prev, linkContactUs: e.target.value };
        });
        break;  
      case "emailOffice":
        setTenant((prev) => {
          return { ...prev, emailOffice: e.target.value };
        });
        break;
      case "fornitorePagamenti":
        setTenant((prev) => {
          return { ...prev, fornitorePagamenti: e.target.value };
        });
        break;
      case "apiPagamenti":
        setTenant((prev) => {
          return { ...prev, apiPagamenti: e.target.value };
        });
        break;
      case "areeAmministrative":
        setTenant((prev) => {
          return { ...prev, areeAmministrative: e.target.value };
        });
        break;
      case "office":
        setTenant((prev) => {
          return { ...prev, office: e.target.value };
        });
        break;
      case "imuYears":
        setTenant((prev) => {
          return { ...prev, imuYears: e.target.value };
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log(tenant)
    getTenent();
  }, []);

  const getTenent = async () => {
    try {
      const response = await GenericAPI.get(listAPI.Tenants, params.id);
      console.log(response.data.tenant);
      setTenant(response.data.tenant);
      setDomain(response.data.domain);
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (selectedOption) => setValue(selectedOption);

  return (
    <MainLayout>
      {
        tenant && domain &&
        <Container className="my-5">
          <h2 className="fw-normal mb-5"> Modifica Comune </h2> {getAlert()}
          <main>
            <div className="py-12">
              {" "}
              <form
                ref={createFormRef}
                name="createForm"
                id="createForm"
                onSubmit={handleSubmit}
              >
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                  <div className="  bg-white overflow-hidden shadow-sm sm:rounded-lg">
                    <div className="p-6 bg-white border-b border-gray-200">
                      <div className="flex items-center justify-between mb-6">
                        <span key="link">
                          <Button onClick={back} color="primary">
                            Indietro
                          </Button>{" "}
                        </span>
                      </div>
                      <br />
                      <br />
                    </div>{" "}
                    <div className="row">
                      <div className="col-6 p-6 bg-white border-b border-gray-200">
                        <div className="flex flex-col">
                        

                        <div className="mb-10">
                        <div class="select-wrapper mb-4">
                            <label htmlFor="saveAll">Aggiorna da API</label>
                            <Select
                              id="saveAll"
                              className="custom-select"
                              name="saveAll"
                              defaultValue="false"
                              onChange={handleChange}
                              options={defaultOptionsApi}
                            />
                            <span className="text-red-600"> </span>
                            </div>
                          </div>


                          <div className="mt-10 mb-4">
                            <Input
                            id="id"
                              type="text"
                              label="Id"
                              name="id"
                              readOnly
                              value={tenant?.id ? tenant?.id : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"></span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Descrizione"
                              placeholder="Descrizione"
                              name="description"
                              defaultValue={
                                tenant?.description ? tenant?.description : ""
                              }
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"></span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Regione"
                              placeholder="Regione"
                              name="country"
                              defaultValue={tenant?.country ? tenant?.country : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Url del sito Regione"
                              placeholder="Url del sito Regione"
                              name="country_url"
                              defaultValue={tenant?.country_url ? tenant?.country_url : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Dominio servizi domain"
                              placeholder="Dominio servizi domain"
                              name="domain"
                              defaultValue={domain?.domain ? domain?.domain : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Dominio servizi tenant"
                              placeholder="Dominio servizi tenant"
                              name="domain_tenant"
                              defaultValue={tenant?.domain ? tenant?.domain : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Codice ente"
                              placeholder="Codice ente"
                              name="codiceEnte"
                              defaultValue={tenant?.codiceEnte ? tenant?.codiceEnte : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Partita iva"
                              placeholder="Partita iva"
                              name="partitaIva"
                              defaultValue={tenant?.partitaIva ? tenant?.partitaIva : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Sito comune"
                              placeholder="Sito comune"
                              name="siteUrl"
                              defaultValue={tenant?.siteUrl ? tenant?.siteUrl : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>

                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Anni Aliquote Imu"
                              placeholder="Inserisci gli anni imu, separati da virgole"
                              name="imuYears"
                              defaultValue={tenant?.imuYears ? tenant?.imuYears : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>

                        </div>
                      </div>
                      <div className="col-6 p-6 bg-white border-b border-gray-200">
                        <div className="flex flex-col">
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Sito servizi comune"
                              placeholder="Sito servizi comune"
                              name="serviceSiteUrl"
                              defaultValue={tenant?.serviceSiteUrl ? tenant?.serviceSiteUrl : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="UAV"
                              placeholder="UAV"
                              name="uav"
                              defaultValue={tenant?.uav ? tenant?.uav : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Link privacy"
                              placeholder="Link privacy"
                              name="linkPrivacy"
                              defaultValue={tenant?.linkPrivacy ? tenant?.linkPrivacy : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Link Contattaci"
                              placeholder="Link Contattaci"
                              name="linkContactUs"
                              defaultValue={tenant?.linkContactUs ? tenant?.linkContactUs : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Email office"
                              placeholder="Email office"
                              name="emailOffice"
                              defaultValue={tenant?.emailOffice ? tenant?.emailOffice : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Fornitore pagamenti"
                              placeholder="Fornitore pagamenti"
                              name="fornitorePagamenti"
                              defaultValue={tenant?.fornitorePagamenti ? tenant?.fornitorePagamenti : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Api pagamenti"
                              placeholder="Api pagamenti"
                              name="apiPagamenti"
                              defaultValue={tenant?.apiPagamenti ? tenant?.apiPagamenti : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Aree amministrative"
                              placeholder="Aree amministrative"
                              name="areeAmministrative"
                              defaultValue={tenant?.areeAmministrative ? tenant?.areeAmministrative : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="mb-0">
                            <Input
                              type="text"
                              label="Uffici"
                              placeholder="Uffici"
                              name="office"
                              defaultValue={tenant?.office ? tenant?.office : ""}
                              onChange={onChangeEdit}
                            />
                            <span className="text-red-600"> </span>
                          </div>
                          <div className="bootstrap-select-wrapper">
                            <label htmlFor="themeSelected">Colore Tema</label>
                            <Select
                              id="themeSelected"
                              name="themeSelected"
                              defaultValue={getSelectedOption(
                                tenant?.themeSelected
                              )}
                              onChange={handleChange}
                              options={defaultOptions}
                              placeholder="Scegli il colore del tema"
                              aria-label="Scegli  il colore del tema"
                            />
                            <span className="text-red-600"> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button color="secondary" disabled={loading}>{loading ? 'Salvataggio in corso...' : 'Salva'}</Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </main>
        </Container>
      }
    </MainLayout>
  );
}
